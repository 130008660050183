header.nav {
  background: #BBD8BA;
  padding: 15px 0;
}
header.nav nav {
  display: flex;
  justify-content: space-between;
}
header.nav nav ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
}
header.nav nav ul li {
  list-style: none;
  position: relative;
  line-height: 92px;
}
header.nav nav ul li a {
  color: #104B50;
  font-weight: 600;
  text-decoration: none;
  font-size: 17px;
}
header.nav nav ul li ul {
  position: absolute;
  min-width: 100px;
  width: max-content;
  max-width: 280px;
  display: none;
  left: 0;
  margin-top: -15px;
  padding: 6px 0;
  transform: translateY(-5px);
}
header.nav nav ul li ul li {
  padding: 0;
  margin: 5px 0;
  line-height: initial;
}
header.nav nav ul li ul li a {
  font-weight: 300;
}
header.nav nav ul li ul li.title a {
  font-weight: bold;
}
header.nav nav ul li ul li:hover {
  display: block;
}
header.nav nav ul li ul:hover {
  display: block;
}
header.nav nav ul li:hover ul {
  display: block;
  animation: hoverMenu 0.3s forwards;
}
header.nav .social {
  width: 100px;
  margin-left: 20px;
}
header.nav .social a {
  text-decoration: none;
}
header.nav .social svg {
  width: 20px;
  margin-left: 5px;
  fill: #104B50;
}

@keyframes hoverMenu {
  0% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
.e-con > .e-con-inner {
  max-width: 1320px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .e-con > .e-con-inner {
    max-width: 1140px;
  }
}

footer {
  background: #BBD8BA;
  padding: 15px 0;
  position: relative;
}
footer .container {
  position: relative;
  z-index: 1;
}
footer canvas {
  position: absolute;
  top: 0;
}
footer nav {
  display: flex;
  justify-content: space-between;
}
footer nav ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
}
footer nav ul li {
  list-style: none;
}
footer nav ul li a {
  color: #104B50;
  font-weight: 600;
  text-decoration: none;
  font-size: 17px;
}
footer .social {
  width: 100px;
  margin-left: 20px;
}
footer .social a {
  text-decoration: none;
}
footer .social svg {
  width: 20px;
  margin-left: 5px;
  fill: #104B50;
}

@media (max-width: 992px) {
  .menu-mobile {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    background: #fff;
    transform: translateX(100%);
    transition: all 0.3s;
  }
  .menu-mobile .button-menu-close {
    position: absolute;
    top: 70px;
    right: 20px;
    background: transparent;
    border: 0;
  }
  .menu-mobile .button-menu-close div {
    width: 27px;
    height: 3px;
    background: #104B50;
  }
  .menu-mobile .button-menu-close div:nth-child(1) {
    transform: rotate(45deg) translateX(2px) translateY(2px);
  }
  .menu-mobile .button-menu-close div:nth-child(2) {
    transform: rotate(-45deg);
  }
  .menu-mobile ul {
    margin: 0;
    padding: 25px;
    display: flex;
    flex-direction: column;
    height: 70%;
    justify-content: space-evenly;
  }
  .menu-mobile ul li {
    list-style: none;
    text-align: center;
    margin-bottom: 20px;
  }
  .menu-mobile ul li a {
    color: #104B50;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
  }
  .menu-mobile ul li a button {
    border: 0;
    color: #FFF;
    background: #30C10B;
    border-radius: 4px;
    padding: 9px 15px;
    font-weight: 600;
  }
  .menu-mobile ul li a:hover {
    color: #FFF;
    text-decoration: none;
  }
  .menu-mobile .social {
    text-align: center;
  }
  .menu-mobile .social button {
    background: transparent;
    border: 0;
    position: relative;
  }
  .menu-mobile .social a {
    text-decoration: none;
  }
  .menu-mobile .social svg {
    width: 25px;
    margin: 5px;
    fill: #104B50;
  }
  .menu-mobile.active {
    transform: translateX(0);
  }
  header.nav {
    position: initial;
    background: #f3ede2;
  }
  header.nav .text-right {
    text-align: right;
  }
  header.nav #button-menu {
    border: 0;
    background: transparent;
  }
  header.nav #button-menu div {
    width: 30px;
    height: 3px;
    background: #104B50;
    margin: 5px 0;
  }
}